export const Types = {
  // ThemeOptions Types
  SET_ENABLE_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE',
  SET_ENABLE_MOBILE_MENU: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU',
  SET_ENABLE_MOBILE_MENU_SMALL: 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL',
  SET_ENABLE_FIXED_HEADER: 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER',
  SET_ENABLE_HEADER_SHADOW: 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW',
  SET_ENABLE_SIDEBAR_SHADOW: 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW',
  SET_ENABLE_FIXED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR',
  SET_ENABLE_CLOSED_SIDEBAR: 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR',
  SET_ENABLE_FIXED_FOOTER: 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER',
  SET_ENABLE_PAGETITLE_ICON: 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON',
  SET_ENABLE_PAGETITLE_SUBHEADING:
    'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING',
  SET_ENABLE_PAGE_TABS_ALT: 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT',
  SET_BACKGROUND_IMAGE: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE',
  SET_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_BACKGROUND_COLOR',
  SET_COLOR_SCHEME: 'THEME_OPTIONS/SET_COLOR_SCHEME',
  SET_BACKGROUND_IMAGE_OPACITY: 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY',
  SET_HEADER_BACKGROUND_COLOR: 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR',
  SET_ACTIVE_TAB: 'THEME_OPTIONS/SET_ACTIVE_TAB',
  SET_MOBILE_SIZE: 'THEME_OPTIONS/SET_MOBILE_SIZE',

  // LoggedIn Types

  // Auth Types
  LOGIN_REQUEST: '@auth/LOGIN_REQUEST',
  LOGIN_EXTERNO_REQUEST: '@auth/LOGIN_EXTERNO_REQUEST',
  LOGIN_SUCCESS: '@auth/LOGIN_SUCCESS',
  FIRST_LOGIN: '@auth/FIRST_LOGIN',
  LOGIN_FAILURE: '@auth/LOGIN_FAILURE',
  REFRESH_TOKEN: '@auth/REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: '@auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: '@auth/REFRESH_TOKEN_FAILURE',
  VALIDATE_EMAIL_REQUEST: '@auth/VALIDATE_EMAIL_REQUEST',
  VALIDATE_EMAIL_RESPONSE: '@auth/VALIDATE_EMAIL_RESPONSE',
  FORGOT_PASSWORD_REQUEST: '@auth/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_RESPONSE: '@auth/FORGOT_PASSWORD_RESPONSE',
  RESET_PASSWORD_REQUEST: '@auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_RESPONSE: '@auth/RESET_PASSWORD_RESPONSE',
  SET_NEW_PASSWORD_REQUEST: '@auth/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_RESPONSE: '@auth/SET_NEW_PASSWORD_RESPONSE',
  CHECK_FOR_UPDATES: '@auth/CHECK_FOR_UPDATES',
  REGISTER_FIREBASE_TOKEN: '@auth/REGISTER_FIREBASE_TOKEN',
  LOGOUT: '@auth/LOGOUT',
  RESET_LOGIN: '@auth/RESET_LOGIN',

  // FolhaCerta Bases Types
  GET_FILIAIS_REQUEST: '@filiais/FOLHACERTA_BASE_REQUEST',
  GET_DEPARTAMENTOS_REQUEST: '@departamentos/FOLHACERTA_BASE_REQUEST',
  GET_CENTROS_DE_CUSTO_REQUEST: '@centrosDeCustos/FOLHACERTA_BASE_REQUEST',
  GET_GRUPOS_REQUEST: '@grupos/FOLHACERTA_BASE_REQUEST',
  GET_PESSOAS_REQUEST: '@pessoas/FOLHACERTA_BASE_REQUEST',
  GET_SINDICATOS_REQUEST: '@sindicatos/FOLHACERTA_BASE_REQUEST',
  GET_PERIODOS_REQUEST: '@periodos/FOLHACERTA_BASE_REQUEST',
  GET_REGRAS_APURACAO_REQUEST: '@regrasApuracao/FOLHACERTA_BASE_REQUEST',
  GET_SERVICOS_ORIGEM_REQUEST: '@servicosOrigem/FOLHACERTA_BASE_REQUEST',
  GET_STATUS_REQUEST: '@status/FOLHACERTA_BASE_REQUEST',
  GET_EMPRESAS_REQUEST: '@empresas/FOLHACERTA_BASE_REQUEST',
  GET_PAIS_REQUEST: '@pais/FOLHACERTA_BASE_REQUEST',
  GET_ESTADO_REQUEST: '@estado/FOLHACERTA_BASE_REQUEST',
  GET_CIDADE_REQUEST: '@cidade/FOLHACERTA_BASE_REQUEST',
  GET_FILIAIS_PAGED_REQUEST: '@filiaisPaged/FOLHACERTA_BASE_REQUEST',
  GET_DEPARTAMENTOS_PAGED_REQUEST: '@departamentosPaged/FOLHACERTA_BASE_REQUEST',
  GET_GRUPOS_PAGED_REQUEST: '@gruposPaged/FOLHACERTA_BASE_REQUEST',
  GET_PESSOAS_PAGED_REQUEST: '@pessoasPaged/FOLHACERTA_BASE_REQUEST',
  GET_SINDICATOS_PAGED_REQUEST: '@sindicatosPaged/FOLHACERTA_BASE_REQUEST',
  GET_CENTROS_DE_CUSTO_PAGED_REQUEST:
    '@centrosDeCustosPaged/FOLHACERTA_BASE_REQUEST',
  GET_EMPRESAS_PAGED_REQUEST: '@empresasPaged/FOLHACERTA_BASE_REQUEST',

  GET_FILIAIS_RESPONSE: '@filiais/FOLHACERTA_BASE_RESPONSE',
  GET_DEPARTAMENTOS_RESPONSE: '@departamentos/FOLHACERTA_BASE_RESPONSE',
  GET_CENTROS_DE_CUSTO_RESPONSE: '@centrosDeCustos/FOLHACERTA_BASE_RESPONSE',
  GET_GRUPOS_RESPONSE: '@grupos/FOLHACERTA_BASE_RESPONSE',
  GET_PESSOAS_RESPONSE: '@pessoas/FOLHACERTA_BASE_RESPONSE',
  GET_SINDICATOS_RESPONSE: '@sindicatos/FOLHACERTA_BASE_RESPONSE',
  GET_PERIODOS_RESPONSE: '@periodos/FOLHACERTA_BASE_RESPONSE',
  GET_REGRAS_APURACAO_RESPONSE: '@regrasApuracao/FOLHACERTA_BASE_RESPONSE',
  GET_SERVICOS_ORIGEM_RESPONSE: '@servicosOrigem/FOLHACERTA_BASE_RESPONSE',
  GET_STATUS_RESPONSE: '@status/FOLHACERTA_BASE_RESPONSE',
  GET_EMPRESAS_RESPONSE: '@empresas/FOLHACERTA_BASE_RESPONSE',
  GET_PAIS_RESPONSE: '@pais/FOLHACERTA_BASE_RESPONSE',
  GET_ESTADO_RESPONSE: '@estado/FOLHACERTA_BASE_RESPONSE',
  GET_CIDADE_RESPONSE: '@cidade/FOLHACERTA_BASE_RESPONSE',
  GET_EMPRESAS_PAGED_RESPONSE: '@empresasPaged/FOLHACERTA_BASE_RESPONSE',
  GET_FILIAIS_PAGED_RESPONSE: '@filiaisPaged/FOLHACERTA_BASE_RESPONSE',
  GET_DEPARTAMENTOS_PAGED_RESPONSE:
    '@departamentosPaged/FOLHACERTA_BASE_RESPONSE',
  GET_CENTROS_DE_CUSTO_PAGED_RESPONSE:
    '@centrosDeCustosPaged/FOLHACERTA_BASE_RESPONSE',
  GET_GRUPOS_PAGED_RESPONSE: '@gruposPaged/FOLHACERTA_BASE_RESPONSE',
  GET_PESSOAS_PAGED_RESPONSE: '@pessoasPaged/FOLHACERTA_BASE_RESPONSE',
  GET_SINDICATOS_PAGED_RESPONSE: '@sindicatosPaged/FOLHACERTA_BASE_RESPONSE',

  // CarregarDownloads Types
  CARREGAR_DOWNLOADS_REQUEST: '@carregarDownloads/REQUEST',
  CARREGAR_DOWNLOADS_RESPONSE: '@carregarDownloads/RESPONSE',
  CARREGAR_DOWNLOADS_ARQUIVO_REQUEST: '@carregarDownloadsArquivo/REQUEST',
  CARREGAR_DOWNLOADS_ARQUIVO_RESPONSE: '@carregarDownloadsArquivo/RESPONSE',
  CARREGAR_DOWNLOADS_ARQUIVOS_REQUEST: '@carregarDownloadsArquivos/REQUEST',
  CARREGAR_DOWNLOADS_ARQUIVOS_RESPONSE: '@carregarDownloadsArquivos/RESPONSE',
  CARREGAR_DOWNLOADS_PARA_QUEM_REQUEST: '@carregarDownloadsParaQuem/REQUEST',
  CARREGAR_DOWNLOADS_PARA_QUEM_RESPONSE: '@carregarDownloadsParaQuem/RESPONSE',

  // CarregarDia3 Types
  CARREGAR_DIA_3_REQUEST: '@carregarDia3/REQUEST',
  CARREGAR_DIA_3_SUCCESS: '@carregarDia3/SUCCESS',
  CARREGAR_DIA_3_FAILURE: '@carregarDia3/FAILURE',

  // carregarUsuariosGestores Types
  CARREGAR_USUARIOS_GESTORES_REQUEST: '@carregarUsuariosGestores/REQUEST',
  CARREGAR_USUARIOS_GESTORES_SUCCESS: '@carregarUsuariosGestores/SUCCESS',
  CARREGAR_USUARIOS_GESTORES_FAILURE: '@carregarUsuariosGestores/FAILURE',

  // carregarAvisoAusenciaLancamentos Types
  CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_REQUEST:
    '@carregarAvisoAusenciaLancamentos/REQUEST',
  CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_SUCCESS:
    '@carregarAvisoAusenciaLancamentos/SUCCESS',
  CARREGAR_AVISO_AUSENCIA_LANCAMENTOS_FAILURE:
    '@carregarAvisoAusenciaLancamentos/FAILURE',

  CARREGAR_AUSENCIA_POR_ID_REQUEST: '@carregarAusenciaPorID/REQUEST',
  CARREGAR_AUSENCIA_POR_ID_SUCCESS: '@carregarAusenciaPorID/SUCCESS',
  CARREGAR_AUSENCIA_POR_ID_FAILURE: '@carregarAusenciaPorID/FAILURE',

  CLEAR_AVISO_AUSENCIA_LANCAMENTO: '@carregarAvisoAusenciaLancamentos/CLEAR',

  // Carregar Banco Horas Atual Types
  CARREGAR_BANCO_HORAS_ATUAL_REQUEST: '@carregarBancoHorasAtual/REQUEST',
  CARREGAR_BANCO_HORAS_ATUAL_SUCCESS: '@carregarBancoHorasAtual/SUCCESS',
  CARREGAR_BANCO_HORAS_ATUAL_FAILURE: '@carregarBancoHorasAtual/FAILURE',

  // Carregar Historico Marcacao
  CARREGAR_HISTORICO_MARCACAO_REQUEST: '@carregarHistoricoMarcacao/REQUEST',
  CARREGAR_HISTORICO_MARCACAO_SUCCESS: '@carregarHistoricoMarcacao/SUCCESS',
  CARREGAR_HISTORICO_MARCACAO_FAILURE: '@carregarHistoricoMarcacao/FAILURE',
  CARREGAR_HISTORICO_MARCACAO_CLEAR_DATA:
    '@carregarHistoricoMarcacao/CLEAR_DATA',

  // MarcarPonto3 Types
  MARCAR_PONTO_3_REQUEST: '@marcarPonto3/REQUEST',
  MARCAR_PONTO_3_SUCCESS: '@marcarPonto3/SUCCESS',
  MARCAR_PONTO_3_FAILURE: '@marcarPonto3/FAILURE',
  MARCAR_PONTO_3_CLEAR_DATA: '@marcarPonto3/CLEAR_DATA',

  // Carregar Motivos Marcacao Types
  CARRGAR_MOTIVOS_MARCACAO_REQUEST: '@carregarMotivosMarcacao/REQUEST',
  CARRGAR_MOTIVOS_MARCACAO_SUCCESS: '@carregarMotivosMarcacao/SUCCESS',
  CARRGAR_MOTIVOS_MARCACAO_FAILURE: '@carregarMotivosMarcacao/FAILURE',

  // Alterar Marcacao Types
  INCLUIR_MARCACAO_MANUAL_REQUEST: '@incluirMarcacaoManual/REQUEST',
  INCLUIR_MARCACAO_MANUAL_SUCCESS: '@incluirMarcacaoManual/SUCCESS',
  INCLUIR_MARCACAO_MANUAL_FAILURE: '@incluirMarcacaoManual/FAILURE',

  ALTERAR_MARCACAO_REQUEST: '@alterarMarcacao/REQUEST',
  ALTERAR_MARCACAO_SUCCESS: '@alterarMarcacao/SUCCESS',
  ALTERAR_MARCACAO_FAILURE: '@alterarMarcacao/FAILURE',

  // Alterar DATA Marcacao Types
  ALTERAR_DATA_MARCACAO_REQUEST: '@alterarDataMarcacao/REQUEST',
  ALTERAR_DATA_MARCACAO_SUCCESS: '@alterarDataMarcacao/SUCCESS',
  ALTERAR_DATA_MARCACAO_FAILURE: '@alterarDataMarcacao/FAILURE',

  // Alterar Status Lançamento Types
  ALTERAR_STATUS_LANCAMENTO_REQUEST: '@alterarStatusLancamento/REQUEST',
  ALTERAR_STATUS_LANCAMENTO_SUCCESS: '@alterarStatusLancamento/SUCCESS',
  ALTERAR_STATUS_LANCAMENTO_FAILURE: '@alterarStatusLancamento/FAILURE',

  // Desconsidera Marcacao Types
  DESCONSIDERAR_MARCACAO_REQUEST: '@desconsiderarMarcacao/REQUEST',
  DESCONSIDERAR_MARCACAO_RESPONSE: '@desconsiderarMarcacao/RESPONSE',

  // Restaurar Marcacao Types
  RESTAURAR_MARCACAO_REQUEST: '@restaurarMarcacao/REQUEST',
  RESTAURAR_MARCACAO_RESPONSE: '@restaurarMarcacao/RESPONSE',

  // Carregar Ferias Lancamentos
  CARREGAR_FERIAS_LANCAMENTOS_REQUEST: '@carregarFeriasLancamentos/REQUEST',
  CARREGAR_FERIAS_LANCAMENTOS_SUCCESS: '@carregarFeriasLancamentos/SUCCESS',
  CARREGAR_FERIAS_LANCAMENTOS_FAILURE: '@carregarFeriasLancamentos/FAILURE',

  FERIAS_POR_ID_REQUEST: '@carregarFeriasPorID/REQUEST',
  FERIAS_POR_ID_RESPONSE: '@carregarFeriasPorID/RESPONSE',

  SALVAR_FERIAS_LANCAMENTO_REQUEST: '@salvarFeriasLancamento/PUT',
  SALVAR_FERIAS_LANCAMENTO_RESPONSE: '@salvarFeriasLancamento/RESPONSE',

  // Carregar DayOff Lancamentos
  CARREGAR_DAYOFF_LANCAMENTOS_REQUEST: '@carregarDayoffLancamentos/REQUEST',
  CARREGAR_DAYOFF_LANCAMENTOS_SUCCESS: '@carregarDayoffLancamentos/SUCCESS',
  CARREGAR_DAYOFF_LANCAMENTOS_FAILURE: '@carregarDayoffLancamentos/FAILURE',

  DAYOFF_LANCAMENTO_POR_ID_REQUEST: '@carregarDayoffLancamentoPorID/REQUEST',
  DAYOFF_LANCAMENTO_POR_ID_RESPONSE: '@carregarDayoffLancamentoPorID/RESPONSE',

  SALVAR_DAYOFF_LANCAMENTO_REQUEST: '@salvarDayoffLancamento/PUT',
  SALVAR_DAYOFF_LANCAMENTO_RESPONSE: '@salvarDayoffLancamento/RESPONSE',

  // Carregar Recesso Lancamentos
  CARREGAR_RECESSO_LANCAMENTOS_REQUEST: '@carregarRecessoLancamentos/REQUEST',
  CARREGAR_RECESSO_LANCAMENTOS_SUCCESS: '@carregarRecessoLancamentos/SUCCESS',
  CARREGAR_RECESSO_LANCAMENTOS_FAILURE: '@carregarRecessoLancamentos/FAILURE',

  CLEAR_ALL_LANCAMENTOS: '@carregarLancamentos/CLEAR_ALL',

  RECESSO_POR_ID_REQUEST: '@carregarRecessoPorID/REQUEST',
  RECESSO_POR_ID_RESPONSE: '@carregarRecessoPorID/RESPONSE',

  SALVAR_RECESSO_LANCAMENTO_REQUEST: '@salvarRecessoLancamento/PUT',
  SALVAR_RECESSO_LANCAMENTO_RESPONSE: '@salvarRecessoLancamento/RESPONSE',

  // Carregar Lancamentos
  CARREGAR_LANCAMENTOS_REQUEST: '@carregarLancamentos/REQUEST',
  CARREGAR_LANCAMENTOS_SUCCESS: '@carregarLancamentos/SUCCESS',
  CARREGAR_LANCAMENTOS_FAILURE: '@carregarLancamentos/FAILURE',

  // Gerenciar Processos Interno
  GERENCIAR_PROCESSOS_INTERNOS_REQUEST: '@gerenciarProcessosInternos/REQUEST',
  GERENCIAR_PROCESSOS_INTERNOS_RESPONSE: '@gerenciarProcessosInternos/RESPONSE',

  // Gerenciar Processos Interno Funcionario
  PROCESSOS_INTERNOS_FUNCIONARIO_REQUEST:
    '@ProcessosInternosFuncionario/REQUEST',
  PROCESSOS_INTERNOS_FUNCIONARIO_RESPONSE:
    '@ProcessosInternosFuncionario/RESPONSE',

  // Solicitar Processos Interno Funcionario
  SOLICITAR_PROCESSO_INTERNO_REQUEST: '@SolicitarProcessosInternos/REQUEST',
  SOLICITAR_PROCESSO_INTERNO_RESPONSE: '@SolicitarProcessosInternos/RESPONSE',

  // Solicitar Processos Interno Funcionario
  LISTAR_PROCESSOS_INTERNOS_REQUEST: '@ListarProcessosInternos/REQUEST',
  LISTAR_PROCESSOS_INTERNOS_RESPONSE: '@ListarProcessosInternos/RESPONSE',

  // Listar Processos Interno Gestor
  PROCESSOS_INTERNOS_REQUEST: '@processosInternos/REQUEST',
  PROCESSOS_INTERNOS_SUCCESS: '@processosInternos/SUCCESS',
  PROCESSOS_INTERNOS_FAILURE: '@processosInternos/FAILURE',
  CLEAR_PROCESSOS_INTERNOS: '@clearAllProcessosInternos',

  // Total Pontos Por Funcionario
  TOTAL_PONTOS_POR_FUNCIONARIO_REQUEST: '@totalPontosPorFuncionario/REQUEST',
  TOTAL_PONTOS_POR_FUNCIONARIO_SUCCESS: '@totalPontosPorFuncionario/SUCCESS',
  TOTAL_PONTOS_POR_FUNCIONARIO_FAILURE: '@totalPontosPorFuncionario/FAILURE',

  // Carregar Mes
  CARREGAR_MES_REQUEST: '@carregarMes/REQUEST',
  CARREGAR_MES_SUCCESS: '@carregarMes/SUCCESS',
  CARREGAR_MES_FAILURE: '@carregarMes/FAILURE',
  CARREGAR_MES_CLEAR_DATA: '@carregarMes/CLEAR_DATA',

  // Carregar Periodo Atual
  CARREGAR_PERIODO_ATUAL_REQUEST: '@carregarPeriodoAtual/REQUEST',
  CARREGAR_PERIODO_ATUAL_SUCCESS: '@carregarPeriodoAtual/SUCCESS',
  CARREGAR_PERIODO_ATUAL_FAILURE: '@carregarPeriodoAtual/FAILURE',

  // carregar Motivos Ausencia
  CARREGAR_MOTIVOS_AUSENCIA_REQUEST: '@carregarMotivosAusencia/REQUEST',
  CARREGAR_MOTIVOS_AUSENCIA_RESPONSE: '@carregarMotivosAusencia/RESPONSE',

  // carregar Ausencias
  CARREGAR_AUSENCIAS_REQUEST: '@carregarAusencias/REQUEST',
  CARREGAR_AUSENCIAS_RESPONSE: '@carregarAusencias/RESPONSE',

  // salvar e alterar Ausencias
  SALVAR_AVISO_AUSENCIA_LANCAMENTO_REQUEST:
    '@salvarAvisoAusenciaLancamento/REQUEST',
  SALVAR_AVISO_AUSENCIA_LANCAMENTO_RESPONSE:
    '@salvarAvisoAusenciaLancamento/RESPONSE',

  ALTERAR_AUSENCIA_LANCAMENTO_REQUEST:
    '@alterarAvisoAusenciaLancamento/REQUEST',
  ALTERAR_AUSENCIA_LANCAMENTO_RESPONSE:
    '@alterarAvisoAusenciaLancamento/RESPONSE',

  // carregar Totais Lancamentos
  CARREGAR_TOTAIS_LANCAMENTOS_REQUEST: '@carregarTotaisLancamentos/REQUEST',
  CARREGAR_TOTAIS_LANCAMENTOS_RESPONSE: '@carregarTotaisLancamentos/RESPONSE',

  // carregar dashboardAprovacoes
  CARREGAR_DASHBOARD_APROVACOES_REQUEST: '@dashboardAprovacoes/REQUEST',
  CARREGAR_DASHBOARD_APROVACOES_RESPONSE: '@dashboardAprovacoes/RESPONSE',

  /* INICIO INTERMITENTES */

  // Cargos
  CARGOS_GET_REQUEST: '@cargosGet/REQUEST',
  CARGOS_GET_SUCCESS: '@cargosGet/SUCCESS',
  CARGOS_GET_FAILURE: '@cargosGet/FAILURE',

  // Locais de Trabalho
  LOCAIS_DE_TRABALHO_REQUEST: '@locaisDeTrabalho/REQUEST',
  LOCAIS_DE_TRABALHO_SUCCESS: '@locaisDeTrabalho/SUCCESS',
  LOCAIS_DE_TRABALHO_FAILURE: '@locaisDeTrabalho/FAILURE',

  // Usuarios Convocacao
  USUARIOS_CONVOCACAO_REQUEST: '@usuariosConvocacao/REQUEST',
  USUARIOS_CONVOCACAO_SUCCESS: '@usuariosConvocacao/SUCCESS',
  USUARIOS_CONVOCACAO_FAILURE: '@usuariosConvocacao/FAILURE',

  USUARIOS_INTERMITENTES_TODOS_REQUEST: '@usuariosIntermitentesTodos/REQUEST',
  USUARIOS_INTERMITENTES_TODOS_SUCCESS: '@usuariosIntermitentesTodos/SUCCESS',
  USUARIOS_INTERMITENTES_TODOS_FAILURE: '@usuariosIntermitentesTodos/FAILURE',

  // Regras Convocacao
  REGRAS_CONVOCACAO_REQUEST: '@regrasConvocacao/REQUEST',
  REGRAS_CONVOCACAO_SUCCESS: '@regrasConvocacao/SUCCESS',
  REGRAS_CONVOCACAO_FAILURE: '@regrasConvocacao/FAILURE',

  REGRAS_ATIVAS_REQUEST: '@regrasAtivas/REQUEST',
  REGRAS_ATIVAS_SUCCESS: '@regrasAtivas/SUCCESS',
  REGRAS_ATIVAS_FAILURE: '@regrasAtivas/FAILURE',

  REGRAS_ARQUIVADAS_REQUEST: '@regrasArquivadas/REQUEST',
  REGRAS_ARQUIVADAS_SUCCESS: '@regrasArquivadas/SUCCESS',
  REGRAS_ARQUIVADAS_FAILURE: '@regrasArquivadas/FAILURE',

  REGRAS_UTILIZADAS_REQUEST: '@regrasUtilizadas/REQUEST',
  REGRAS_UTILIZADAS_SUCCESS: '@regrasUtilizadas/SUCCESS',
  REGRAS_UTILIZADAS_FAILURE: '@regrasUtilizadas/FAILURE',

  REGRAS_CONVOCACAO_GET_REQUEST: '@regrasConvocacaoGet/REQUEST',
  REGRAS_CONVOCACAO_GET_SUCCESS: '@regrasConvocacaoGet/SUCCESS',
  REGRAS_CONVOCACAO_GET_FAILURE: '@regrasConvocacaoGet/FAILURE',

  REGRAS_CONVOCACAO_PUT_REQUEST: '@regrasConvocacaoPUT/REQUEST',
  REGRAS_CONVOCACAO_PUT_RESPONSE: '@regrasConvocacaoPUT/RESPONSE',

  REGRAS_CLEAR_DATA: '@regrasClearData/FAILURE',

  // Contratantes
  CONTRATANTES_REQUEST: '@contratantes/REQUEST',
  CONTRATANTES_SUCCESS: '@contratantes/SUCCESS',
  CONTRATANTES_FAILURE: '@contratantes/FAILURE',

  CONTRATANTES_ATIVAS_REQUEST: '@contratantesAtivas/REQUEST',
  CONTRATANTES_ATIVAS_SUCCESS: '@contratantesAtivas/SUCCESS',
  CONTRATANTES_ATIVAS_FAILURE: '@contratantesAtivas/FAILURE',

  CONTRATANTES_ARQUIVADAS_REQUEST: '@contratantesArquivadas/REQUEST',
  CONTRATANTES_ARQUIVADAS_SUCCESS: '@contratantesArquivadas/SUCCESS',
  CONTRATANTES_ARQUIVADAS_FAILURE: '@contratantesArquivadas/FAILURE',

  CONTRATANTES_TODOS_REQUEST: '@contratantesTodos/REQUEST',
  CONTRATANTES_TODOS_SUCCESS: '@contratantesTodos/SUCCESS',
  CONTRATANTES_TODOS_FAILURE: '@contratantesTodos/FAILURE',

  CONTRATANTES_DISPONIVEIS_REQUEST: '@contratantesDisponiveis/REQUEST',
  CONTRATANTES_DISPONIVEIS_SUCCESS: '@contratantesDisponiveis/SUCCESS',
  CONTRATANTES_DISPONIVEIS_FAILURE: '@contratantesDisponiveis/FAILURE',

  CONTRATANTES_RESUMO_ID_REQUEST: '@convocacaoResumoById/REQUEST',
  CONTRATANTES_RESUMO_ID_SUCCESS: '@convocacaoResumoById/SUCCESS',
  CONTRATANTES_RESUMO_ID_FAILURE: '@convocacaoResumoById/FAILURE',

  CONTRATANTES_ARQUIVAR_REQUEST: '@convocacaoArquivar/REQUEST',
  CONTRATANTES_ARQUIVAR_RESPONSE: '@convocacaoArquivar/SUCCESS',

  CONTRATANTES_DESARQUIVAR_REQUEST: '@convocacaoDesarquivar/REQUEST',
  CONTRATANTES_DESARQUIVAR_RESPONSE: '@convocacaoDesarquivar/SUCCESS',

  CONTRATANTES_EDITAR_REQUEST: '@convocacaoEditar/REQUEST',
  CONTRATANTES_EDITAR_RESPONSE: '@convocacaoEditar/RESPONSE',

  CONTRATANTES_CLEAR_DATA: '@contratantesClearData/FAILURE',

  // Convocacao
  NOVA_CONVOCACAO_REQUEST: '@novaConvocacao/REQUEST',
  NOVA_CONVOCACAO_SUCCESS: '@novaConvocacao/SUCCESS',
  NOVA_CONVOCACAO_FAILURE: '@novaConvocacao/FAILURE',

  CONVOCACAO_LISTA_REQUEST: '@convocacaoLista/REQUEST',
  CONVOCACAO_LISTA_SUCCESS: '@convocacaoLista/SUCCESS',
  CONVOCACAO_LISTA_FAILURE: '@convocacaoLista/FAILURE',

  CONVOCACAO_INTERROMPER_REQUEST: '@convocacaoInterromper/REQUEST',
  CONVOCACAO_INTERROMPER_SUCCESS: '@convocacaoInterromper/SUCCESS',
  CONVOCACAO_INTERROMPER_FAILURE: '@convocacaoInterromper/FAILURE',

  CONVOCACAO_VENCENDO_REQUEST: '@convocacaoVencendo/REQUEST',
  CONVOCACAO_VENCENDO_RESPONSE: '@convocacaoVencendo/RESPONSE',

  NOVA_CONVOCACAO_CLEAR_DATA: '@novaConvocacaoClearData/FAILURE',

  // Departamentos
  DEPARTAMENTOS_REQUEST: '@departamentos/REQUEST',
  DEPARTAMENTOS_SUCCESS: '@departamentos/SUCCESS',
  DEPARTAMENTOS_FAILURE: '@departamentos/FAILURE',

  // Grupo
  GRUPO_GET_REQUEST: '@grupoGet/REQUEST',
  GRUPO_GET_SUCCESS: '@grupoGet/SUCCESS',
  GRUPO_GET_FAILURE: '@grupoGet/FAILURE',

  GRUPO_POST_REQUEST: '@grupoPost/REQUEST',
  GRUPO_POST_SUCCESS: '@grupoPost/SUCCESS',
  GRUPO_POST_FAILURE: '@grupoPost/FAILURE',

  GRUPO_EDITAR_REQUEST: '@grupoEditar/REQUEST',
  GRUPO_EDITAR_RESPONSE: '@grupoEditar/RESPONSE',

  GRUPO_ARQUIVAR_REQUEST: '@grupoArquivar/REQUEST',
  GRUPO_ARQUIVAR_RESPONSE: '@grupoArquivar/RESPONSE',

  GRUPO_DESARQUIVAR_REQUEST: '@grupoDesarquivar/REQUEST',
  GRUPO_DESARQUIVAR_RESPONSE: '@grupoDesarquivar/RESPONSE',

  GRUPO_ATIVOS_REQUEST: '@grupoAtivos/REQUEST',
  GRUPO_ATIVOS_SUCCESS: '@grupoAtivos/SUCCESS',
  GRUPO_ATIVOS_FAILURE: '@grupoAtivos/FAILURE',

  GRUPO_ARQUIVADOS_REQUEST: '@grupoArquivados/REQUEST',
  GRUPO_ARQUIVADOS_SUCCESS: '@grupoArquivados/SUCCESS',
  GRUPO_ARQUIVADOS_FAILURE: '@grupoArquivados/FAILURE',

  GRUPO_TODOS_REQUEST: '@grupoTodos/REQUEST',
  GRUPO_TODOS_SUCCESS: '@grupoTodos/SUCCESS',
  GRUPO_TODOS_FAILURE: '@grupoTodos/FAILURE',

  GRUPO_CLEAR_DATA: '@grupoClear/FAILURE',

  // Motivos de Interrupção
  MOTIVOS_INTERRUPCAO_POST_REQUEST: '@motivosInterrupcaoPost/REQUEST',
  MOTIVOS_INTERRUPCAO_POST_SUCCESS: '@motivosInterrupcaoPost/SUCCESS',
  MOTIVOS_INTERRUPCAO_POST_FAILURE: '@motivosInterrupcaoPost/FAILURE',

  MOTIVOS_INTERRUPCAO_GET_REQUEST: '@motivosInterrupcaoGet/REQUEST',
  MOTIVOS_INTERRUPCAO_GET_SUCCESS: '@motivosInterrupcaoGet/SUCCESS',
  MOTIVOS_INTERRUPCAO_GET_FAILURE: '@motivosInterrupcaoGet/FAILURE',

  MOTIVOS_INTERRUPCAO_PUT_REQUEST: '@motivosInterrupcaoPut/REQUEST',
  MOTIVOS_INTERRUPCAO_PUT_RESPONSE: '@motivosInterrupcaoPut/RESPONSE',

  MOTIVOS_INTERRUPCAO_CLEAR_DATA: '@motivosInterrupcaoClearData/FAILURE',

  // Relatório SAP
  RELATORIO_SAP_REQUEST: '@relatorioSAP/REQUEST',
  RELATORIO_SAP_RESPONSE: '@relatorioSAP/RESPONSE',

  // API Banco Horas
  API_BANCO_HORAS_REQUEST: '@relatorioBancoHoras/REQUEST',
  RELATORIO_BANCO_HORAS_RESPONSE: '@relatorioBancoHoras/RESPONSE',
  LISTA_BANCO_HORAS_RESPONSE: '@listaBancoHoras/RESPONSE',
  LISTA_BANCO_HORAS_POR_USUARIO_RESPONSE: '@listaBancoHorasPorUsuario/RESPONSE',
  LISTA_CICLOS_RESPONSE: '@listaCiclosBancoHoras/RESPONSE',
  LISTA_PERIODO_RESPONSE: '@listaPeriodoBancoHoras/RESPONSE',
  LISTA_DEMONSTRATIVO_RESPONSE: '@listaDemonstrativoBancoHoras/RESPONSE',
  LISTA_FECHAMENTO_RESPONSE: '@listaFechamento/RESPONSE',

  // Relatório API
  CLEAR_RELATORIO: '@relatorioAPI/CLEAR/REQUEST',
  RELATORIO_API_REQUEST: '@relatorioAPI/REQUEST',

  RELATORIO_LOG_CALCULO_RESPONSE: '@relatorioLogCalculo/RESPONSE',
  RELATORIO_COMPLETO_VERBAS_DIA_RESPONSE: '@relatorioCompletoPorDia/RESPONSE',
  RELATORIO_EXPORTACAO_VERBAS_RESPONSE: '@relatorioExportacaoVerbas/RESPONSE',
  RELATORIO_EXPORTACAO_VERBAS_ADP_GLOBALVIEW:
    '@relatorioAdpGlobalView/RESPONSE',

  RELATORIO_COMPLETO_BANCO_DIA_HORAS_RESPONSE:
    '@relatorioCompletoBancoHorasDia/RESPONSE',
  RELATORIO_BANCO_HORAS_DIA_RESPONSE: '@listaCompletoBancoHorasDia/RESPONSE',

  RELATORIO_COMPLETO_BANCO_PERIODO_HORAS_RESPONSE:
    '@relatorioCompletoBancoHorasPeriodo/RESPONSE',
  RELATORIO_BANCO_HORAS_PERIODO_RESPONSE:
    '@listaCompletoBancoHorasPeriodo/RESPONSE',
  RELATORIO_RH_EVOLUTION_RESPONSE: '@relatorioRHevolution/RESPONSE',
  RELATORIO_SINERGY_RH_RESPONSE: '@relatorioSinergyRH/RESPONSE',

  /* FIM INTERMITENTES */

  /* INICIO SOBREAVISO */
  // Motivo
  MOTIVO_SOBREAVISO_REQUEST: '@motivoSobreaviso/REQUEST',
  MOTIVO_SOBREAVISO_RESPONSE: '@motivoSobreaviso/RESPONSE',
  MOTIVO_SOBREAVISO_REQUEST_PUT: '@motivoSobreavisoPUT/REQUEST',
  MOTIVO_SOBREAVISO_RESPONSE_PUT: '@motivoSobreavisoPUT/RESPONSE',
  MOTIVO_SOBREAVISO_REQUEST_GET: '@motivoSobreavisoGet/REQUEST',
  MOTIVO_SOBREAVISO_RESPONSE_GET: '@motivoSobreavisoGet/RESPONSE',
  MOTIVO_SOBREAVISO_BY_ID_REQUEST_GET: '@motivoSobreavisoGetId/REQUEST',
  MOTIVO_SOBREAVISO_BY_ID_RESPONSE_GET: '@motivoSobreavisoGetId/RESPONSE',

  MOTIVO_SOBREAVISO_HISTORICO_REQUEST: '@motivoSobreavisoHistorico/REQUEST',
  MOTIVO_SOBREAVISO_HISTORICO_RESPONSE: '@motivoSobreavisoHistorico/RESPONSE',
  MOTIVO_SOBREAVISO_CLEAR_DATA: '@motivoSobreaviso/CLEAR',

  // Alocação
  ALOCACAO_SOBREAVISO_REQUEST: '@alocacaoSobreaviso/REQUEST',
  ALOCACAO_SOBREAVISO_RESPONSE: '@alocacaoSobreaviso/RESPONSE',
  ALOCACAO_SOBREAVISO_REQUEST_PUT: '@alocacaoSobreavisoPUT/REQUEST',
  ALOCACAO_SOBREAVISO_RESPONSE_PUT: '@alocacaoSobreavisoPUT/RESPONSE',
  ALOCACAO_SOBREAVISO_REQUEST_GET: '@alocacaoSobreavisoGet/REQUEST',
  ALOCACAO_SOBREAVISO_RESPONSE_GET: '@alocacaoSobreavisoGet/RESPONSE',
  ALOCACAO_SOBREAVISO_BY_ID_REQUEST_GET: '@alocacaoSobreavisoGetId/REQUEST',
  ALOCACAO_SOBREAVISO_BY_ID_RESPONSE_GET: '@alocacaoSobreavisoGetId/RESPONSE',
  ALOCACAO_SOBREAVISO_HISTORICO_REQUEST: '@alocacaoSobreavisoHistorico/REQUEST',
  ALOCACAO_SOBREAVISO_HISTORICO_RESPONSE:
    '@alocacaoSobreavisoHistorico/RESPONSE',

  ALOCACAO_SOBREAVISO_CLEAR_DATA: '@alocacaoSobreaviso/CLEAR',

  // Regra
  REGRA_SOBREAVISO_REQUEST: '@regraSobreaviso/REQUEST',
  REGRA_SOBREAVISO_RESPONSE: '@regraSobreaviso/RESPONSE',
  REGRA_SOBREAVISO_REQUEST_GET: '@regraSobreavisoGet/REQUEST',
  REGRA_SOBREAVISO_RESPONSE_GET: '@regraSobreavisoGet/RESPONSE',
  REGRA_SOBREAVISO_BY_ID_REQUEST_GET: '@regraSobreavisoGetId/REQUEST',
  REGRA_SOBREAVISO_BY_ID_RESPONSE_GET: '@regraSobreavisoGetId/RESPONSE',
  REGRA_SOBREAVISO_REQUEST_PUT: '@regraSobreavisoPUT/REQUEST',
  REGRA_SOBREAVISO_RESPONSE_PUT: '@regraSobreavisoPUT/RESPONSE',
  REGRA_SOBREAVISO_HISTORICO_REQUEST: '@regraSobreavisoHistorico/REQUEST',
  REGRA_SOBREAVISO_HISTORICO_RESPONSE: '@regraSobreavisoHistorico/RESPONSE',

  REGRA_SOBREAVISO_BY_USUARIO_LOGADO_REQUEST: '@regraSobreavisoUsuarioLogado/REQUEST',
  REGRA_SOBREAVISO_BY_USUARIO_LOGADO_RESPONSE: '@regraSobreavisoUsuarioLogado/RESPONSE',

  REGRA_SOBREAVISO_CLEAR_DATA: '@regraSobreaviso/CLEAR',

  // Lançamento
  LANCAMENTO_SOBREAVISO_REQUEST: '@lancamentoSobreaviso/REQUEST',
  LANCAMENTO_SOBREAVISO_RESPONSE: '@lancamentoSobreaviso/RESPONSE',
  LANCAMENTO_SOBREAVISO_REQUEST_GET: '@lancamentoSobreavisoGet/REQUEST',
  LANCAMENTO_SOBREAVISO_RESPONSE_GET: '@lancamentoSobreavisoGet/RESPONSE',
  LANCAMENTO_SOBREAVISO_BY_ID_REQUEST_GET: '@lancamentoSobreavisoGetId/REQUEST',
  LANCAMENTO_SOBREAVISO_BY_ID_RESPONSE_GET:
    '@lancamentoSobreavisoGetId/RESPONSE',
  LANCAMENTO_SOBREAVISO_REQUEST_PUT: '@lancamentoSobreavisoPUT/REQUEST',
  LANCAMENTO_SOBREAVISO_RESPONSE_PUT: '@lancamentoSobreavisoPUT/RESPONSE',
  LANCAMENTO_SOBREAVISO_HISTORICO_REQUEST:
    '@lancamentoSobreavisoHistorico/REQUEST',
  LANCAMENTO_SOBREAVISO_HISTORICO_RESPONSE:
    '@lancamentoSobreavisoHistorico/RESPONSE',
  LANCAMENTO_SOBREAVISO_RELATORIO_REQUEST:
    '@lancamentoSobreavisoRelatorio/REQUEST',
  LANCAMENTO_SOBREAVISO_RELATORIO_RESPONSE:
    '@lancamentoSobreavisoRelatorio/RESPONSE',
  LANCAMENTO_SOBREAVISO_RELATORIO_CSV_REQUEST:
    '@lancamentoSobreavisoRelatorioCSV/REQUEST',
  LANCAMENTO_SOBREAVISO_RELATORIO_CSV_RESPONSE:
    '@lancamentoSobreavisoRelatorioCSV/RESPONSE',

  LANCAMENTO_SOBREAVISO_ACAO_REQUEST: '@lancamentoSobreavisoAcao/REQUEST',
  LANCAMENTO_SOBREAVISO_ACAO_RESPONSE: '@lancamentoSobreavisoAcao/RESPONSE',

  LANCAMENTO_SOBREAVISO_COUNT_REQUEST: '@lancamentoSobreavisoCount/REQUEST',
  LANCAMENTO_SOBREAVISO_COUNT_RESPONSE: '@lancamentoSobreavisoCount/RESPONSE',
  LANCAMENTO_SOBREAVISO_CLEAR_CSV: '@lancamentoSobreaviso/CLEAR_CSV',
  LANCAMENTO_SOBREAVISO_CLEAR_DATA: '@lancamentoSobreaviso/CLEAR',

  /* FIM SOBREAVISO */

  // Locale Types
  CHOOSE_LOCALE: '@locale/CHOOSE_LOCALE',

  /* INICIO MOTOR DE CALCULO */
  CLEAR_ALL_MOTOR_NOVO: '@clearAll/MOTOR_NOVO',

  // Regras de Apuração
  REGRA_APURACAO_REQUEST: '@regraApuracao/REQUEST',
  GET_REGRA_APURACAO_RESPONSE: '@regraApuracao/GET/RESPONSE',
  POST_REGRA_APURACAO_RESPONSE: '@regraApuracao/POST/RESPONSE',
  PUT_REGRA_APURACAO_RESPONSE: '@regraApuracao/PUT/RESPONSE',
  DELETE_REGRA_APURACAO_RESPONSE: '@regraApuracao/DELETE/RESPONSE',
  REGRA_APURACAO_DUPLICATE_REQUEST: '@regraApuracaoDuplicate/REQUEST',
  REGRA_APURACAO_DUPLICATE_RESPONSE: '@regraApuracaoDuplicate/RESPONSE',

  // formaApuracao
  FORMA_APURACAO_REQUEST: '@formaApuracao/REQUEST',
  GET_FORMA_APURACAO_RESPONSE: '@formaApuracao/GET/RESPONSE',
  POST_FORMA_APURACAO_RESPONSE: '@formaApuracao/POST/RESPONSE',
  PUT_FORMA_APURACAO_RESPONSE: '@formaApuracao/PUT/RESPONSE',
  DELETE_FORMA_APURACAO_RESPONSE: '@formaApuracao/DELETE/RESPONSE',

  // Tolerancia
  TOLERANCIA_REQUEST: '@tolerancia/REQUEST',
  GET_TOLERANCIA_RESPONSE: '@tolerancia/GET/RESPONSE',
  POST_TOLERANCIA_RESPONSE: '@tolerancia/POST/RESPONSE',
  PUT_TOLERANCIA_RESPONSE: '@tolerancia/PUT/RESPONSE',

  // horaNoturna
  HORA_NOTURNA_REQUEST: '@horaNoturna/REQUEST',
  GET_HORA_NOTURNA_RESPONSE: '@horaNoturna/GET/RESPONSE',
  POST_HORA_NOTURNA_RESPONSE: '@horaNoturna/POST/RESPONSE',
  PUT_HORA_NOTURNA_RESPONSE: '@horaNoturna/PUT/RESPONSE',

  // horaPositiva
  HORA_POSITIVA_REQUEST: '@horaPositiva/REQUEST',
  GET_HORA_POSITIVA_RESPONSE: '@horaPositiva/GET/RESPONSE',
  POST_HORA_POSITIVA_RESPONSE: '@horaPositiva/POST/RESPONSE',
  PUT_HORA_POSITIVA_RESPONSE: '@horaPositiva/PUT/RESPONSE',

  // horaPositiva Faixas
  HORA_POSITIVA_FAIXAS_REQUEST: '@horaPositivaFaixas/REQUEST',
  GET_HORA_POSITIVA_FAIXAS_RESPONSE: '@horaPositivaFaixas/GET/RESPONSE',
  POST_HORA_POSITIVA_FAIXAS_RESPONSE: '@horaPositivaFaixas/POST/RESPONSE',
  PUT_HORA_POSITIVA_FAIXAS_RESPONSE: '@horaPositivaFaixas/PUT/RESPONSE',

  // horaNegativa
  HORA_NEGATIVA_REQUEST: '@horaNegativa/REQUEST',
  GET_HORA_NEGATIVA_RESPONSE: '@horaNegativa/GET/RESPONSE',
  POST_HORA_NEGATIVA_RESPONSE: '@horaNegativa/POST/RESPONSE',
  PUT_HORA_NEGATIVA_RESPONSE: '@horaNegativa/PUT/RESPONSE',

  // faltas
  FALTAS_REQUEST: '@faltas/REQUEST',
  GET_FALTAS_RESPONSE: '@faltas/GET/RESPONSE',
  POST_FALTAS_RESPONSE: '@faltas/POST/RESPONSE',
  PUT_FALTAS_RESPONSE: '@faltas/PUT/RESPONSE',

  // ausencias
  AUSENCIAS_REQUEST: '@ausencias/REQUEST',
  GET_AUSENCIAS_RESPONSE: '@ausencias/GET/RESPONSE',
  POST_AUSENCIAS_RESPONSE: '@ausencias/POST/RESPONSE',
  PUT_AUSENCIAS_RESPONSE: '@ausencias/PUT/RESPONSE',

  // intervalo
  INTERVALO_REQUEST: '@intervalo/REQUEST',
  GET_INTERVALO_RESPONSE: '@intervalo/GET/RESPONSE',
  POST_INTERVALO_RESPONSE: '@intervalo/POST/RESPONSE',
  PUT_INTERVALO_RESPONSE: '@intervalo/PUT/RESPONSE',

  // domingo dsr
  DOMINGO_DSR_REQUEST: '@domingoDsr/REQUEST',
  GET_DOMINGO_DSR_RESPONSE: '@domingoDsr/GET/RESPONSE',
  POST_DOMINGO_DSR_RESPONSE: '@domingoDsr/POST/RESPONSE',
  PUT_DOMINGO_DSR_RESPONSE: '@domingoDsr/PUT/RESPONSE',

  // inconsistencia
  INCONSISTENCIA_REQUEST: '@inconsistencia/REQUEST',
  GET_INCONSISTENCIA_RESPONSE: '@inconsistencia/GET/RESPONSE',
  POST_INCONSISTENCIA_RESPONSE: '@inconsistencia/POST/RESPONSE',
  PUT_INCONSISTENCIA_RESPONSE: '@inconsistencia/PUT/RESPONSE',

  // tratamento
  TRATAMENTO_REQUEST: '@tratamento/REQUEST',
  GET_TRATAMENTO_RESPONSE: '@tratamento/GET/RESPONSE',
  POST_TRATAMENTO_RESPONSE: '@tratamento/POST/RESPONSE',
  PUT_TRATAMENTO_RESPONSE: '@tratamento/PUT/RESPONSE',

  // descanso obrigatorio
  DESCANSO_OBRIGATORIO_REQUEST: '@descansoObrigatorio/REQUEST',
  GET_DESCANSO_OBRIGATORIO_RESPONSE: '@descansoObrigatorio/GET/RESPONSE',
  POST_DESCANSO_OBRIGATORIO_RESPONSE: '@descansoObrigatorio/POST/RESPONSE',
  PUT_DESCANSO_OBRIGATORIO_RESPONSE: '@descansoObrigatorio/PUT/RESPONSE',

  // Regra Ponto
  REGRA_PONTO_REQUEST: '@regraPonto/REQUEST',
  REGRA_PONTO_BY_EMPRESA_REQUEST: '@regraPontoByEmpresaId/REQUEST',
  GET_REGRA_DEFAULT_PONTO_REQUEST: '@regraPontoDefault/REQUEST',
  REGRA_PONTO_BY_EMPRESA_RESPONSE: '@regraPontoByEmpresaId/RESPONSE',
  GET_REGRA_DEFAULT_PONTO_RESPONSE: '@regraPontoDefault/RESPONSE',
  GET_REGRA_PONTO_RESPONSE: '@regraPonto/GET/RESPONSE',
  POST_REGRA_PONTO_RESPONSE: '@regraPonto/POST/RESPONSE',
  PUT_REGRA_PONTO_RESPONSE: '@regraPonto/PUT/RESPONSE',

  // Alocação Regra de Apuração
  ALOCACAO_REGRA_APURACAO_REQUEST: '@alocacaoRegraApuracao/REQUEST',
  ALOCACAO_REGRA_APURACAO_RESPONSE: '@alocacaoRegraApuracao/RESPONSE',
  ALOCACAO_REGRA_APURACAO_REQUEST_PUT: '@alocacaoRegraApuracaoPUT/REQUEST',
  ALOCACAO_REGRA_APURACAO_REQUEST_STATUS_PUT: '@alocacaoRegraApuracaoStatusPUT/REQUEST',
  ALOCACAO_REGRA_APURACAO_RESPONSE_PUT: '@alocacaoRegraApuracaoPUT/RESPONSE',
  ALOCACAO_REGRA_APURACAO_RESPONSE_STATUS_PUT: '@alocacaoRegraApuracaoStatusPUT/RESPONSE',
  ALOCACAO_REGRA_APURACAO_REQUEST_GET: '@alocacaoRegraApuracaoGet/REQUEST',
  ALOCACAO_REGRA_APURACAO_RESPONSE_GET: '@alocacaoRegraApuracaoGet/RESPONSE',
  ALOCACAO_REGRA_APURACAO_BY_ID_REQUEST_GET:
    '@alocacaoRegraApuracaoGetId/REQUEST',
  ALOCACAO_REGRA_APURACAO_BY_ID_RESPONSE_GET:
    '@alocacaoRegraApuracaoGetId/RESPONSE',
  ALOCACAO_REGRA_APURACAO_HISTORICO_REQUEST:
    '@alocacaoRegraApuracaoHistorico/REQUEST',
  ALOCACAO_REGRA_APURACAO_HISTORICO_RESPONSE:
    '@alocacaoRegraApuracaoHistorico/RESPONSE',

  ALOCACAO_REGRA_APURACAO_COUNT_REQUEST: '@alocacaoRegraApuracaoCount/REQUEST',
  ALOCACAO_REGRA_APURACAO_COUNT_RESPONSE:
    '@alocacaoRegraApuracaoCount/RESPONSE',
  ALOCACAO_REGRA_APURACAO_CLEAR_DATA: '@alocacaoRegraApuracao/CLEAR',

  // Mensageria
  MENSAGERIA_REQUEST: '@mensageria/REQUEST',

  MENSAGERIA_LISTA_SUCCESS: '@mensageriaLista/SUCCESS',
  MENSAGERIA_LISTA_FAILURE: '@mensageriaLista/FAILURE',

  MENSAGERIA_RELATORIO_SUCCESS: '@mensageriaRelatorio/SUCCESS',
  MENSAGERIA_RELATORIO_FAILURE: '@mensageriaRelatorio/FAILURE',

  MENSAGERIA_HISTORICO_SUCCESS: '@mensageriaHistorico/SUCCESS',
  MENSAGERIA_HISTORICO_FAILURE: '@mensageriaHistorico/FAILURE',

  CLEAR_MENSAGERIA: '@mensageria/CLEAR',

  // Relatorio Marcacao de Ponto
  RELATORIO_MARCACAO_REQUEST: '@relatorioMarcacao/REQUEST',

  RELATORIO_MARCACAO_LISTA_SUCCESS: '@relatorioMarcacaoLista/SUCCESS',
  RELATORIO_MARCACAO_LISTA_FAILURE: '@relatorioMarcacaoLista/FAILURE',

  RELATORIO_MARCACAO_RELATORIO_SUCCESS: '@relatorioMarcacaoRelatorio/SUCCESS',
  RELATORIO_MARCACAO_RELATORIO_FAILURE: '@relatorioMarcacaoRelatorio/FAILURE',

  CLEAR_RELATORIO_MARCACAO: '@relatorioMarcacao/CLEAR',

  // Configuração de Verbas DE - PARA
  CONFIG_VERBAS_REQUEST: '@configVerbas/REQUEST',
  CONFIG_VERBAS_SUCCESS: '@configVerbas/SUCCESS',
  CONFIG_VERBAS_FAILURE: '@configVerbas/FAILURE',

  // PUT config Portal  
  PUT_CONFIG_PORTAL_REQUEST: '@putConfigPortalVerbas/REQUEST',  
  PUT_CONFIG_PORTAL_SUCCESS: '@putConfigPortalVerbas/SUCCESS',  
  PUT_CONFIG_PORTAL_FAILURE: '@putConfigPortalVerbas/FAILURE',

  PUT_CONFIG_REQUEST: '@putConfigVerbas/REQUEST',
  PUT_CONFIG_RESPONSE: '@putConfigVerbas/RESPONSE',

  CONFIG_VERBAS_DETALHES_REQUEST: '@configDetalhesVerbas/REQUEST',
  CONFIG_VERBAS_DETALHES_SUCCESS: '@configDetalhesVerbas/SUCCESS',
  CONFIG_VERBAS_DETALHES_FAILURE: '@configDetalhesVerbas/FAILURE',

  CADASTRO_VERBAS_REQUEST: '@cadastroVerbas/REQUEST',
  CADASTRO_VERBAS_SUCCESS: '@cadastroVerbas/SUCCESS',
  CADASTRO_VERBAS_FAILURE: '@cadastroVerbas/FAILURE',

  ALOCACAO_CONFIG_VERBAS_REGRA_REQUEST: '@alocacaoConfigVerbasRegra/REQUEST',
  ALOCACAO_CONFIG_VERBAS_REGRA_SUCCESS: '@alocacaoConfigVerbasRegra/SUCCESS',
  ALOCACAO_CONFIG_VERBAS_REGRA_FAILURE: '@alocacaoConfigVerbasRegra/FAILURE',

  GET_VERBAS: '@getVerbas/REQUEST',
  GET_VERBAS_RESPONSE: '@getVerbas/RESPONSE',
  CONFIG_VERBAS_CLEAR_ALL: '@configVerbas/CLEAR_ALL',

  /* BANCO DE HORAS */
  // banco de Horas Configuracao
  BANCO_HORAS_CONFIGURACAO_REQUEST: '@bancoHorasConfiguracao/REQUEST',
  GET_BANCO_HORAS_CONFIGURACAO_RESPONSE: '@bancoHorasConfiguracao/GET/RESPONSE',
  POST_BANCO_HORAS_CONFIGURACAO_RESPONSE:
    '@bancoHorasConfiguracao/POST/RESPONSE',
  PUT_BANCO_HORAS_CONFIGURACAO_RESPONSE: '@bancoHorasConfiguracao/PUT/RESPONSE',

  // banco de Horas Configuracao Faixas
  BANCO_HORAS_CONFIGURACAO_FAIXA_REQUEST:
    '@bancoHorasConfiguracaoFaixa/REQUEST',
  GET_BANCO_HORAS_CONFIGURACAO_FAIXA_RESPONSE:
    '@bancoHorasConfiguracaoFaixa/GET/RESPONSE',
  POST_BANCO_HORAS_CONFIGURACAO_FAIXA_RESPONSE:
    '@bancoHorasConfiguracaoFaixa/POST/RESPONSE',
  PUT_BANCO_HORAS_CONFIGURACAO_FAIXA_RESPONSE:
    '@bancoHorasConfiguracaoFaixa/PUT/RESPONSE',

  // banco de Horas Configuracao Faixas Verbas
  BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_REQUEST:
    '@bancoHorasConfiguracaoFaixaVerba/REQUEST',
  GET_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoFaixaVerba/GET/RESPONSE',
  POST_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoFaixaVerba/POST/RESPONSE',
  PUT_BANCO_HORAS_CONFIGURACAO_FAIXA_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoFaixaVerba/PUT/RESPONSE',

  // banco de Horas Configuracao ciclo
  BANCO_HORAS_CONFIGURACAO_CICLO_REQUEST:
    '@bancoHorasConfiguracaoCiclo/REQUEST',
  GET_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE:
    '@bancoHorasConfiguracaoCiclo/GET/RESPONSE',
  POST_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE:
    '@bancoHorasConfiguracaoCiclo/POST/RESPONSE',
  PUT_BANCO_HORAS_CONFIGURACAO_CICLO_RESPONSE:
    '@bancoHorasConfiguracaoCiclo/PUT/RESPONSE',

  // banco de Horas Configuracao ciclo Verba
  BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_REQUEST:
    '@bancoHorasConfiguracaoCicloVerba/REQUEST',
  GET_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoCicloVerba/GET/RESPONSE',
  POST_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoCicloVerba/POST/RESPONSE',
  PUT_BANCO_HORAS_CONFIGURACAO_CICLO_VERBA_RESPONSE:
    '@bancoHorasConfiguracaoCicloVerba/PUT/RESPONSE',

  // banco de Horas Estagio
  BANCO_HORAS_ESTAGIO_REQUEST: '@bancoHorasEstagio/REQUEST',
  GET_BANCO_HORAS_ESTAGIO_RESPONSE: '@bancoHorasEstagio/GET/RESPONSE',
  POST_BANCO_HORAS_ESTAGIO_RESPONSE: '@bancoHorasEstagio/POST/RESPONSE',
  PUT_BANCO_HORAS_ESTAGIO_RESPONSE: '@bancoHorasEstagio/PUT/RESPONSE',

  // banco de Horas Estagio Verba
  BANCO_HORAS_ESTAGIO_VERBA_REQUEST: '@bancoHorasEstagioVerba/REQUEST',
  GET_BANCO_HORAS_ESTAGIO_VERBA_RESPONSE:
    '@bancoHorasEstagioVerba/GET/RESPONSE',
  POST_BANCO_HORAS_ESTAGIO_VERBA_RESPONSE:
    '@bancoHorasEstagioVerba/POST/RESPONSE',
  PUT_BANCO_HORAS_ESTAGIO_VERBA_RESPONSE:
    '@bancoHorasEstagioVerba/PUT/RESPONSE',

  // banco de Hora
  BANCO_HORA_REQUEST: '@bancoHora/REQUEST',
  GET_BANCO_HORA_RESPONSE: '@bancoHora/GET/RESPONSE',
  POST_BANCO_HORA_RESPONSE: '@bancoHora/POST/RESPONSE',
  PUT_BANCO_HORA_RESPONSE: '@bancoHora/PUT/RESPONSE',

  // ciclo
  BANCO_CICLO_REQUEST: '@bancoCiclo/REQUEST',
  GET_BANCO_CICLO_RESPONSE: '@bancoCiclo/GET/RESPONSE',
  POST_BANCO_CICLO_RESPONSE: '@bancoCiclo/POST/RESPONSE',
  PUT_BANCO_CICLO_RESPONSE: '@bancoCiclo/PUT/RESPONSE',
  BANCO_CICLO_ACTIVATE_REQUEST: '@bancoCicloActivate/PUT/REQUEST',
  BANCO_CICLO_ACTIVATE_RESPONSE: '@bancoCicloActivate/PUT/RESPONSE',
  BANCO_CICLO_INACTIVATE_REQUEST: '@bancoCicloInactivate/PUT/REQUEST',
  BANCO_CICLO_INACTIVATE_RESPONSE: '@bancoCicloInactivate/PUT/RESPONSE',
  BANCO_CICLO_BATCH_REQUEST: '@bancoCicloBatch/REQUEST',
  BANCO_CICLO_BATCH_RESPONSE: '@bancoCicloBatch/RESPONSE',

  /* FIM BANCO DE HORAS */

  /* FIM MOTOR DE CALCULO */

  /* INICIO INDICADORES */
  INDICADORES_REQUEST: '@indicadores/REQUEST',
  INDICADORES_FAILURE: '@indicadores/FAILURE',
  CLEAR_INDICADORES: '@indicadores/CLEAR_ALL',

  INDICADORES_GET_SUCCESS: '@indicadoresGet/SUCCESS',
  INDICADORES_POST_SUCCESS: '@indicadoresPost/SUCCESS',
  INDICADORES_GET_ID_SUCCESS: '@indicadoresGetById/SUCCESS',
  INDICADORES_PUT_SUCCESS: '@indicadoresPUT/SUCCESS',
  /* FIM INDICADORES */

  /* ADMIN MASTER */

  // Reprocessamento
  PEDIDO_REPROCESSAMENTO_REQUEST: '@pedidoReprocessamento/REQUEST',
  PEDIDO_REPROCESSAMENTO_DETALHE_REQUEST:
    '@pedidoReprocessamentoDetalhe/REQUEST',
  PEDIDO_REPROCESSAMENTO_LOG_REQUEST: '@pedidoReprocessamentoLog/REQUEST',
  PEDIDO_REPROCESSAMENTO_FAILURE: '@pedidoReprocessamento/FAILURE',
  CLEAR_REPROCESSAMENTO: '@reprocessamento/CLEAR_ALL',

  PEDIDO_GET_SUCCESS: '@pedidoReprocessamentoGet/SUCCESS',
  PEDIDO_POST_SUCCESS: '@pedidoReprocessamentoPost/SUCCESS',
  PEDIDO_GET_ID_SUCCESS: '@getReprocessamentoID/SUCCESS',
  PEDIDO_DETALHE_SUCCESS: '@pedidoReprocessamentoDetalhe/SUCCESS',
  PEDIDO_LOG_SUCCESS: '@pedidoReprocessamentoLog/SUCCESS',

  // Holerite
  FILTRAR_FILIAIS_REQUEST: '@filtrarFiliais/REQUEST',
  FILTRAR_FILIAIS_RESPONSE: '@filtrarFiliais/RESPONSE',

  FILTRAR_DEPARTAMENTOS_REQUEST: '@filtrarDepartamentos/REQUEST',
  FILTRAR_DEPARTAMENTOS_RESPONSE: '@filtrarDepartamentos/RESPONSE',

  FILTRAR_CENTRO_CUSTOS_REQUEST: '@filtrarCentroCustos/REQUEST',
  FILTRAR_CENTRO_CUSTOS_RESPONSE: '@filtrarCentroCustos/RESPONSE',

  FILTRAR_GRUPOS_REQUEST: '@filtrarGrupos/REQUEST',
  FILTRAR_GRUPOS_RESPONSE: '@filtrarGrupos/RESPONSE',

  FILTRAR_SINDICATOS_REQUEST: '@filtrarSindicatos/REQUEST',
  FILTRAR_SINDICATOS_RESPONSE: '@filtrarSindicatos/RESPONSE',

  FILTRAR_PESSOAS_REQUEST: '@filtrarPessoas/REQUEST',
  FILTRAR_PESSOAS_RESPONSE: '@filtrarPessoas/RESPONSE',

  REQUEST_LISTAR_HOLERITES: '@listarHolerites/REQUEST',
  RESPONSE_LISTAR_HOLERITES: '@listarHolerites/RESPONSE',
  RESPONSE_IMPORTACAO_CLEAR_DATA: '@importacao/FAILURE',

  REQUEST_LISTA_TIPOS_HOLERITES: '@listaTiposHolerites/REQUEST',
  RESPONSE_LISTA_TIPOS_HOLERITES: '@listaTiposHolerites/RESPONSE',

  REQUEST_IMPORTACAO: '@importacao/REQUEST',
  RESPONSE_IMPORTACAO: '@importacao/RESPONSE',

  REQUEST_IMPORTACAO_ARQUIVO: '@importacaoArquivo/REQUEST',
  RESPONSE_IMPORTACAO_ARQUIVO: '@importacaoArquivo/RESPONSE',

  REQUEST_DELETE_OPTIONS: '@deleteOptions/REQUEST',
  REQUEST_DELETE_OPTIONS_GROUP: '@deleteOptionsGroup/REQUEST',

  RESPONSE_DELETE_OPTIONS: '@deleteOptions/RESPONSE',
  RESPONSE_DELETE_OPTIONS_GROUP: '@deleteOptionsGroup/RESPONSE',

  // bancohoraPgtoAbonoExtraordinario
  REQUEST_PGTO_ABONO_EXTRAORDINARIO: '@pgtoAbonoExtraordinario/REQUEST',
  LISTA_PGTO_ABONO_EXTRAORDINARIO: '@listaPgtoAbonoExtraordinario/RESPONSE',
  NOVO_PGTO_ABONO_EXTRAORDINARIO: '@novoPgtoAbonoExtraordinario/RESPONSE',
  DELETE_PGTO_ABONO_EXTRAORDINARIO: '@deletePgtoAbonoExtraordinario/RESPONSE',
  SALDO_PGTO_ABONO_EXTRAORDINARIO: '@saldoPgtoAbonoExtraordinario/RESPONSE',
  LIQUIDACAO_ANTECIPADA_PGTO_ABONO_EXTRAORDINARIO:
    '@liquidacaoAntecipadaPgtoAbono/RESPONSE',

  // bancoHoras - SaldoAnterior
  REQUEST_SALDO_ANTERIOR: '@saldoAnterior/REQUEST',
  RESPONSE_LISTA_SALDO_ANTERIOR: '@listaSaldoAnterior/RESPONSE',
  DELETE_SALDO_ANTERIOR: '@deleteSaldoAnterior/RESPONSE',

  // bancoHoras - SaldoAnterior
  REQUEST_FECHAMENTO_ANTECIPADO: '@fechamentoAntecipado/REQUEST',
  RESPONSE_LISTA_FECHAMENTO_ANTECIPADO: '@listaFechamentoAntecipado/RESPONSE',
  RESPONSE_LISTA_PERIODO_FECHAMENTO_ANTECIPADO:
    '@listaPeriodoFechamentoAntecipado/RESPONSE',

  // Exportação customizada de verbas
  CLEAR_EXPORTCACAO_VERBAS: '@exportacaoCustomizada/CLEAR',
  REQUEST_EXPORTACAO_VERBAS: '@exportacaoCustomizada/REQUEST',
  REQUEST_BY_ID_EXPORTACAO_VERBAS: '@exportacaoCustomizada/REQUEST_BY_ID',
  LISTA_LAYOUTS_RESPONSE: '@exportacaoCustomizadaLista/RESPONSE',
  VERBAS_BY_LAYOUT_TIPO_RESPONSE: '@getVerbasByLayoutTipo/RESPONSE',
  NOVO_LAYOUT_RESPONSE: '@novoLayoutExportacaoCustomizada/RESPONSE',
  UPDATE_LAYOUT_RESPONSE: '@updateLayoutExportacaoCustomizada/RESPONSE',
  LAYOUT_BY_ID_RESPONSE: '@layoutByIdExportacaoCustomizada/RESPONSE',
  LISTA_LAYOUTS_BY_ID_RESPONSE:
    '@listaLayoutsByIdExportacaoCustomizada/RESPONSE',
  NOVA_EXPORTACAO_LAYOUT_RESPONSE: '@novaExportacaoCustomizada/RESPONSE',
  REQUEST_TIPO_ASSINATURA_ELETRONICA: '@tipoAssinaturaEletronica/REQUEST',
  TIPO_ASSINATURA_ELETRONICA_RESPONSE: '@tipoAssinaturaEletronica/RESPONSE',

  // escalas
  REQUEST_REGRAS_CONDICOES_TRABALHO: '@regrasCondicoesTrabalho/REQUEST',
  RESPONSE_REGRAS_CONDICOES_TRABALHO: '@regrasCondicoesTrabalho/RESPONSE',

  // ALOCAÇÃO DE ESCALAS
  REQUEST_ALOCACAO_ESCALAS: '@alocacaoEscalas/REQUEST',
  REQUEST_ALOCACAO_HISTORICO: '@alocacaoEscalasHistorico/REQUEST',
  REQUEST_PUT_POST_ALOCACAO_ESCALAS: '@alocacaoEscalasPutAndPost/REQUEST',
  REQUEST_ALOCACAO_ESCALAS_BY_ID: '@alocacaoEscalasById/REQUEST',
  RESPONSE_PUT_POST_ALOCACAO_ESCALAS: '@alocacaoEscalasResponse/RESPONSE',
  RESPONSE_ALOCACAO_ESCALAS: '@alocacaoEscalas/RESPONSE',
  RESPONSE_ALOCACAO_HISTORICO: '@alocacaoEscalasHistorico/RESPONSE',
  RESPONSE_ALOCACAO_ESCALAS_BY_ID: '@alocacaoEscalasById/RESPONSE',
  RESPONSE_ALOCACAO_HISTORICO_BY_ID: '@alocacaoEscalasHistoricoById/RESPONSE',
  CLEAR_ALOCACAO_ESCALAS: '@alocacaoEscalas/CLEAR',
  REQUEST_REGRAS_CONDICOES_TRABALHO_POR_ID:
    '@regrasCondicoesTrabalhoPorId/REQUEST',
  RESPONSE_REGRAS_CONDICOES_TRABALHO_POR_ID:
    '@regrasCondicoesTrabalhoPorId/RESPONSE',

  ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO:
    '@alteraStatusRegrasCondicoesTrabalho/REQUEST',
  RESPONSE_ALTERA_STATUS_REGRAS_CONDICOES_TRABALHO:
    '@alteraStatusRegrasCondicoesTrabalho/RESPONSE',

  REQUEST_COMBO_CONDICOES_TRABALHO: '@comboRegrasCondicoesTrabalho/REQUEST',
  RESPONSE_COMBO_CONDICOES_TRABALHO: '@comboRegrasCondicoesTrabalho/RESPONSE',

  REQUEST_REGRAS_GESTAO_ESCALA: '@regrasGestaoEscala/REQUEST',
  RESPONSE_REGRAS_GESTAO_ESCALA: '@regrasGestaoEscala/RESPONSE',
  RESPONSE_REGRAS_GESTAO_ESCALA_COMBO: '@regrasGestaoEscalaCombo/RESPONSE',

  REQUEST_REGRAS_GESTAO_POR_ID: '@regrasGestaoPorId/REQUEST',
  RESPONSE_REGRAS_GESTAO_POR_ID: '@regrasGestaoPorId/RESPONSE',

  ALTERA_STATUS_REGRAS_GESTAO: '@alteraStatusRegrasGestao/REQUEST',
  RESPONSE_ALTERA_STATUS_REGRAS_GESTAO: '@alteraStatusRegrasGestao/RESPONSE',

  RESPONSE_TURNO: '@turno/RESPONSE',
  REQUEST_TURNO: '@turno/REQUEST',
  REQUEST_POST_AND_PUT_TURNO: '@turnoPostAndPut/REQUEST',
  RESPONSE_POST_AND_PUT_TURNO: '@turnoPostAndPut/RESPONSE',

  // ESCALAS DE TRABALHO
  REQUEST_CRIAR_ESCALA_TRABALHO: '@escalaDeTrabalhoCriar/REQUEST',
  REQUEST_ESCALA_TRABALHO: '@escalaDeTrabalho/REQUEST',
  RESPONSE_ESCALA_TRABALHO: '@escalaDeTrabalho/RESPONSE',
  GET_ESCALA_BY_ID: '@getEscalaDeTrabalho/RESPONSE',
  GET_ESCALA_PADRAO: '@getEscalaPadrao/RESPONSE',
  CLEAR_ESCALAS_TRABALHO: '@escalaDeTrabalho/CLEAR',

  REQUEST_CALENDARIO_EQUIPE: '@calendarioEquipe/REQUEST',
  RESPONSE_CALENDARIO_EQUIPE: '@calendarioEquipe/RESPONSE',

  REQUEST_TAGS: '@tags/REQUEST',
  RESPONSE_TAGS: '@tags/RESPONSE',

  REQUEST_ACOES: '@acoes/REQUEST',
  RESPONSE_ACOES: '@acoes/RESPONSE',
  RESPONSE_ACAO_POR_ID: '@acoesPorId/RESPONSE',

  REQUEST_ESCALAS_EM_USO: '@escalasEmUso/REQUEST',
  RESPONSE_ESCALAS_EM_USO: '@escalasEmUso/RESPONSE',

  // bancoHoras - Espelho de ponto
  ESPELHO_PONTO_BANCO_HORA_REQUEST: '@espelhoPontoBancoHoras/REQUEST',
  TOTAIS_ESPELHO_BANCO_HORA: '@listaFechamentoAntecipado/RESPONSE',

  // DIVERGENCIAS
  REQUEST_OCORRENCIAS: '@ocorrencias/REQUEST',
  RESPONSE_OCORRENCIAS_APROVACOES: '@ocorrenciasAprovacoes/RESPONSE',
  RESPONSE_OCORRENCIAS_USUARIO: '@ocorrenciasUsuario/RESPONSE',
  CLEAR_DIVERGENCIA: '@ocorrenciasusuario/CLEAR',
};
