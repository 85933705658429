import produce from 'immer';

import { Types } from '~/store/modules/types';

const INITIAL_STATE = {
  loading: false,

  saldo: null,
  lista: null,
};

export default function bancohoraPgtoAbonoExtraordinario(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.REQUEST_PGTO_ABONO_EXTRAORDINARIO: {
        draft.loading = true;
        break;
      }

      case Types.LISTA_PGTO_ABONO_EXTRAORDINARIO: {
        draft.lista = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.SALDO_PGTO_ABONO_EXTRAORDINARIO: {
        draft.saldo = action.payload.data;
        draft.loading = false;
        break;
      }

      case Types.NOVO_PGTO_ABONO_EXTRAORDINARIO: {
        draft.loading = false;
        break;
      }

      case Types.DELETE_PGTO_ABONO_EXTRAORDINARIO: {
        draft.loading = false;
        break;
      }

      case Types.LIQUIDACAO_ANTECIPADA_PGTO_ABONO_EXTRAORDINARIO: {
        draft.loading = false;
        break;
      }

      case Types.CLEAR_RELATORIO: {
        draft.loading = false;
        draft.saldo = null;
        draft.lista = null;
        break;
      }

      case Types.LOGOUT: {
        draft.loading = false;
        draft.saldo = null;
        draft.lista = null;
        break;
      }
      default:
    }
  });
}
