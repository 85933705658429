import { combineReducers } from 'redux';

import auth from './auth/reducer';
import loginPayload from './loggedIn/reducer';
import themeOptions from './themeOptions/reducer';
import locale from './locale/reducer';
import carregarDia3 from './carregarDia3/reducer';
import marcarPonto3 from './marcarPonto3/reducer';
import carregarBancoHorasAtual from './carregarBancoHorasAtual/reducer';
import carregarMotivoMarcacao from './carregarMotivoMarcacao/reducer';
import alterarMarcacao from './marcacaoPonto/alterar/reducer';
import restaurarMarcacao from './marcacaoPonto/restaurar/reducer';
import desconsiderarMarcacao from './marcacaoPonto/desconsiderar/reducer';
import alterarStatusLancamento from './marcacaoPonto/alterarStatusLancamento/reducer';
import carregarLancamentos from './carregarLancamentos/reducer';
import carregarMes from './carregarMes/reducer';
import carregarPeriodoAtual from './carregarPeriodoAtual/reducer';
import carregarHistoricoMarcacao from './carregarHistoricoMarcacao/reducer';
import carregarAvisoAusenciaLancamentos from './carregarAvisoAusenciaLancamentos/reducer';
import cargosGet from './cargosIntermitentes/reducer';
import download from './download/reducer';
import locaisDeTrabalho from './locaisDeTrabalho/reducer';
import usuariosConvocacao from './usuariosConvocacao/reducer';
import regrasConvocacao from './regrasConvocacao/reducer';
import departamentos from './departamentos/reducer';
import grupo from './grupo/reducer';
import grupoAtivos from './grupo/ativos/reducer';
import grupoArquivados from './grupo/arquivados/reducer';
import grupoTodos from './grupo/todos/reducer';
import contratantes from './contratantes/reducer';
import convocacao from './convocacao/reducer';
import motivosInterrupcao from './motivosInterrupcao/reducer';
import relatorioSap from './relatorio/reducer';
import regrasApuracao from './motorNovo/regrasApuracao/reducer';
import formaApuracao from './formaApuracao/reducer';
import carregarMotivosAusencia from './carregarMotivosAusencia/reducer';
import carregarAusencias from './carregarAusencias/reducer';
import carregarTotaisLancamentos from './carregarTotaisLancamentos/reducer';
import salvarAvisoAusenciaLancamento from './salvarAvisoAusenciaLancamento/reducer';
import dashboardAprovacoes from './dashboardAprovacoes/reducer';
import motivoSobreaviso from './motivoSobreaviso/reducer';
import regraSobreaviso from './regraSobreaviso/reducer';
import alocacaoSobreaviso from './alocacaoSobreaviso/reducer';
import folhacertabase from './folhacertabase/reducer';
import lancamentoSobreaviso from './lancamentoSobreaviso/reducer';
import horaNoturna from './motorNovo/horaNoturna/reducer';
import tolerancia from './motorNovo/tolerancia/reducer';
import horaPositiva from './motorNovo/horaPositiva/reducer';
import horaPositivaFaixas from './motorNovo/horaPositivaFaixas/reducer';
import horaNegativa from './motorNovo/horaNegativa/reducer';
import faltas from './motorNovo/faltas/reducer';
import ausencias from './motorNovo/ausencias/reducer';
import descansoObrigatorio from './motorNovo/descansoObrigatorio/reducer';
import domingoDsr from './motorNovo/domingoDsr/reducer';
import inconsistencia from './motorNovo/Inconsistencia/reducer';
import tratamento from './motorNovo/tratamento/reducer';
import intervalo from './motorNovo/intervalo/reducer';
import regraPonto from './motorNovo/regraPonto/reducer';
import alocacaoRegraApuracao from './motorNovo/alocacaoRegraApuracao/reducer';
import relatorioApi from './apiRelatorios/reducer';
import configDePara from './configuracaoDePara/reducer';
import mensageria from './mensageria/reducer';
import marcacoesRelatorio from './marcacoesRelatorio/reducer';
import bancoHorasConfiguracao from './motorNovo/BancoHoras/bancoHorasConfiguracao/reducer';
import bancoHorasConfiguracaoFaixa from './motorNovo/BancoHoras/bancoHorasConfiguracaoFaixa/reducer';
import bancoHorasConfiguracaoFaixaVerba from './motorNovo/BancoHoras/bancoHorasConfiguracaoFaixaVerba/reducer';
import bancoHorasConfiguracaoCiclo from './motorNovo/BancoHoras/bancoHorasConfiguracaoCiclo/reducer';
import bancoHorasConfiguracaoCicloVerba from './motorNovo/BancoHoras/bancoHorasConfiguracaoCicloVerba/reducer';
import bancoHorasEstagio from './motorNovo/BancoHoras/bancoHorasEstagio/reducer';
import bancoHorasEstagioVerba from './motorNovo/BancoHoras/bancoHorasEstagioVerba/reducer';
import bancoHora from './motorNovo/BancoHoras/bancoHora/reducer';
import ciclo from './motorNovo/BancoHoras/ciclo/reducer';
import reprocessamento from './reprocessamento/reducer';
import indicadores from './indicadores/reducer';
import processosInternos from './processosInternos/reducer';
import gerenciarProcessosInternos from './gerenciarProcessosInternos/reducer';
import carregarUsuariosGestores from './carregarUsuariosGestores/reducer';
import filtrarFiliais from './holerite/filiais/reducer';
import filtrarDepartamentos from './holerite/departamentos/reducer';
import filtrarCentrosCusto from './holerite/centroCustos/reducer';
import filtrarGrupos from './holerite/grupos/reducer';
import filtrarSindicatos from './holerite/sindicatos/reducer';
import filtrarPessoas from './holerite/pessoas/reducer';
import listarHolerites from './holerite/listagem/reducer';
import importarHolerites from './holerite/importacao/reducer';
import deleteOptions from './holerite/delete/reducer';
import relatorioApiBancoHoras from './apiBancoHoras/reducer';
import bancohoraPgtoAbonoExtraordinario from './bancoHoras/pagamentoAbonoExtraordinario/reducer';
import saldoAnterior from './bancoHoras/saldoAnterior/reducer';
import fechamento from './bancoHoras/fechamentoAntecipado/reducer';
import layoutExportacao from './layoutExportacao/reducer';
import regrasCondicoesTrabalho from './escalas/configuracoes/regrasCondicoesTrabalho/reducer';
import regrasGestaoEscala from './escalas/configuracoes/regrasGestaoEscala/reducer';
import alocacaoEscalas from './escalas/configuracoes/alocacao/reducer';
import espelhoPontoBancoHoras from './bancoHoras/espelhoDePonto/reducer';
import escalasTrabalho from './escalas/configuracoes/escalaTrabalho/reducer';
import calendarioEquipe from './escalas/equipe/reducer';
import tags from './escalas/tags/reducer';
import turno from './escalas/configuracoes/turno/reducer';
import escalasEmUso from './escalas/escalasEmUso/reducer';
import divergencia from './divergencia/reducer';
import acao from './escalas/acoes/reducer';
import listaTiposHolerites from './holerite/tipoHolerite/reducer';

export default combineReducers({
  auth,
  loginPayload,
  themeOptions,
  locale,
  carregarDia3,
  marcarPonto3,
  carregarBancoHorasAtual,
  carregarMotivoMarcacao,
  alterarMarcacao,
  restaurarMarcacao,
  desconsiderarMarcacao,  
  alterarStatusLancamento,
  carregarLancamentos,
  carregarMes,
  carregarPeriodoAtual,
  carregarHistoricoMarcacao,
  carregarAvisoAusenciaLancamentos,
  cargosGet,
  download,
  usuariosConvocacao,
  locaisDeTrabalho,
  regrasConvocacao,
  departamentos,
  grupo,
  grupoAtivos,
  grupoArquivados,
  grupoTodos,
  contratantes,
  convocacao,
  motivosInterrupcao,
  relatorioSap,
  regrasApuracao,
  formaApuracao,
  carregarMotivosAusencia,
  carregarAusencias,
  carregarTotaisLancamentos,
  salvarAvisoAusenciaLancamento,
  dashboardAprovacoes,
  motivoSobreaviso,
  regraSobreaviso,
  alocacaoSobreaviso,
  folhacertabase,
  lancamentoSobreaviso,
  horaNoturna,
  tolerancia,
  horaPositiva,
  horaNegativa,
  faltas,
  ausencias,
  descansoObrigatorio,
  domingoDsr,
  inconsistencia,
  tratamento,
  intervalo,
  regraPonto,
  alocacaoRegraApuracao,
  relatorioApi,
  configDePara,
  mensageria,
  marcacoesRelatorio,
  bancoHorasConfiguracao,
  bancoHorasConfiguracaoCiclo,
  bancoHora,
  ciclo,
  bancoHorasConfiguracaoCicloVerba,
  reprocessamento,
  indicadores,
  processosInternos,
  gerenciarProcessosInternos,
  carregarUsuariosGestores,
  filtrarFiliais,
  filtrarDepartamentos,
  filtrarCentrosCusto,
  filtrarGrupos,
  filtrarSindicatos,
  filtrarPessoas,
  listarHolerites,
  horaPositivaFaixas,
  bancoHorasConfiguracaoFaixa,
  bancoHorasConfiguracaoFaixaVerba,
  importarHolerites,
  deleteOptions,
  relatorioApiBancoHoras,
  bancohoraPgtoAbonoExtraordinario,
  saldoAnterior,
  fechamento,
  bancoHorasEstagio,
  bancoHorasEstagioVerba,
  layoutExportacao,
  regrasCondicoesTrabalho,
  regrasGestaoEscala,
  alocacaoEscalas,
  escalasTrabalho,
  espelhoPontoBancoHoras,
  calendarioEquipe,
  tags,
  turno,
  escalasEmUso,
  divergencia,
  acao,
  listaTiposHolerites,
});
